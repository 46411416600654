
import { mapGetters, mapActions } from "vuex";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import { FormatDate } from "@/shared/helpers/dateHelpers";
import { AttendanceTableColumns } from "@/shared/utils/tableHeaders";
import { FormatList } from "@/shared/helpers/filterHelpers";
import { defineComponent } from "@vue/composition-api";

interface ComponentData {
  search: string;
  group: any;
}

export default defineComponent({
  name: "AttendanceListPage",
  components: { BaseBreadCrumbs },
  data: function (): ComponentData {
    return {
      search: "",
      group: "date",
    };
  },
  computed: {
    ...mapGetters("attendance", ["getAttendanceList"]),
    tableHeaders() {
      return FormatList(AttendanceTableColumns, "text");
    },
    attendanceItems() {
      let attendanceTable: any[] = [];
      (this as any).getAttendanceList.forEach((item: any, index: any) => {
        const date = FormatDate(item.start);
        const status = (value: any) => {
          if (value === 0) {
            return "Ikke utfylt";
          }
          if (value < 100 && value > 0) {
            return "Delvis utfylt";
          }
          if (value === 100) {
            return "Ferdig";
          }
        };
        const attendanceItem = {
          id: index,
          dayId: item.courseDay,
          name: item.courseName,
          date: date,
          title: item.title,
          courseId: item.courseId,
          scheduleStart: item.scheduleStart,
          completed: status(item.completed),
          completedValue: item.completed,
          status: "Ny",
          comment: "",
        };
        attendanceTable.push(attendanceItem);
      });
      return attendanceTable;
    },
  },
  methods: {
    ...mapActions("attendance", ["fetchAttendanceList"]),
    ...mapActions("course", ["reset", "fetchCourse"]),
    groupNone() {
      this.group = undefined;
    },
    groupCompletion() {
      this.group = "completed";
    },
    gropuDate() {
      this.group = "date";
    },
    groupCourse() {
      this.group = "name";
    },
    linkAttendance(event: any) {
      this.$router.push({
        name: "course-attendance-day",
        params: { id: event.courseId, dayId: event.dayId },
      });
    },
    getColor(factor: any) {
      if (factor === 100) {
        return "green";
      }
      if (factor < 100 && factor > 0) {
        return "orange";
      }
      return "red";
    },
  },
  async created() {
    await (this as any).fetchAttendanceList();
  },
});
